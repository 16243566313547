import React from 'react';
import cn from 'classnames';
import './preloader.scss';

const Preloader = ({ isNeutralColor = false }) => {
  return (
    <div className={'loader-wrap'}>
      <div className={cn('loader', isNeutralColor && 'loader__neutral')} />
    </div>
  );
};

export default Preloader;
