import React, { useEffect } from 'react';

import './documents.scss';
import { DashboardLayout } from '../UserView/DashboardLayout';
import Page from '../Page/Page';
import FileUploadComponent from '../../components/ui/FileUploadComponent';
import { FormattedMessage } from 'react-intl';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_SIZE_WIDTH } from '../../constants/mobile';
import { useIntl } from 'react-intl';

const Documents = () => {
  const intl = useIntl();
  const width = useWindowWidth();

  const isMobile = () => width < MOBILE_SIZE_WIDTH;
  const changingTabTitle = () => {
    const title = intl.formatMessage({
      id: 'menu.documents',
      defaultMessage: 'Documents',
    });
    document.title = title;
  };

  useEffect(() => {
    changingTabTitle();
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <div className="documents">
          <div className={'row'}>
            <div className={'col documents-text'}>
              <h3 className={'title'}>
                <FormattedMessage
                  id="page.documents.descr"
                  defaultMessage="Verification documentation"
                />
              </h3>
              <div className={'footer-text'}>
                <span className={'sub-title'}>
                  <FormattedMessage
                    id="page.documents.text.title"
                    defaultMessage="Please provide us with the requested documents."
                  />
                </span>
                <span>
                  <FormattedMessage
                    id="page.documents.text_1"
                    defaultMessage="To fully verify your account and activate it, you will be required to provide us with colored photos/scans of the following documents, making sure that each document is completely and clearly visible, including its edges. Black and White copies and/or copies in which the documents' edges are not visible WILL NOT be accepted."
                  />
                </span>
                <span>
                  <FormattedMessage
                    id="page.documents.text_2"
                    defaultMessage="Please be aware that KYC documents are collected from the owner of the trading account - the depositor, therefore all documents shall match the details of the owner of the bank account or credit card from which the deposits are done."
                  />
                </span>
                <span className={'sub-title'}>
                  <FormattedMessage
                    id="page.documents.text_3.title"
                    defaultMessage="1. Proof of Identity"
                  />
                </span>
                <span>
                  <FormattedMessage
                    id="page.documents.text_3"
                    defaultMessage="Please, upload a colored copy of any of the following document clearly showing the four corners, ID number and full name. In addition, it must show a valid date of expiration"
                  />
                </span>
                <span>
                  <ul className={'list'}>
                    <li>
                      <FormattedMessage id="page.documents.text_3-1" defaultMessage="Passport" />
                    </li>
                    <li>
                      <FormattedMessage
                        id="page.documents.text_3-1-2"
                        defaultMessage="National ID"
                      />
                    </li>
                  </ul>
                </span>
                <span className={'sub-title'}>
                  <FormattedMessage
                    id="page.documents.text_4.title"
                    defaultMessage="2. Proof of Residency"
                  />
                </span>
                <span>
                  <FormattedMessage
                    id="page.documents.text_4"
                    defaultMessage="An official document dated within the last 3 months, issued in your name, showing your full address, with the logo of the issuer. As a Proof of Residency, you can upload:"
                  />
                </span>
                <span>
                  <ul className={'list'}>
                    <li>
                      <FormattedMessage
                        id="page.documents.text_4-1"
                        defaultMessage="Utility bills (Electricity bills, water supply bills, landline phone bills, or gas bills, mobile and internet bills)"
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="page.documents.text_4-2"
                        defaultMessage="Residence certificate issued by a governmental authority"
                      />
                    </li>
                  </ul>
                </span>
                <span className={'sub-title'}>
                  <FormattedMessage
                    id="page.documents.text_5.title"
                    defaultMessage="3. Bank statements (statement about issuing a new CC or opening a new bank account or any other action performed with customer's account given that this document is not older than 3 months)"
                  />
                </span>
                <span className={'sub-title'}>
                  <FormattedMessage
                    id="page.documents.text_6.title"
                    defaultMessage="4. Tax certificate"
                  />
                </span>

                <span className={'sub-title'}>
                  <FormattedMessage
                    id="page.documents.text_7.title"
                    defaultMessage="5. Declaration of the deposit (DOD)"
                  />
                </span>
                <span>
                  <FormattedMessage
                    id="page.documents.text_7"
                    defaultMessage="Confirmation of your online transaction"
                  />
                </span>
              </div>
            </div>
            <div className={`col ${isMobile() ? 'mobile' : ''}`}>
              <div className={'m-r'}>
                <FileUploadComponent id="uploaer" isMultiple={false}></FileUploadComponent>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default Documents;
