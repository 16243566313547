interface ITheme {
  body_background_color: string;
  border_background_color: string;
  main_color_hover: string;
  header_background_color: string;
  main_color: string;
  disabled_text_color: string;
  primary_text_color: string;
  main_light_color: string;
  main_background_color: string;
  secondary_text_color: string;
  logo_src: string;
  sign_up: string;
  sign_in: string;
  reset_password: string;
  sidebar_background_color: string;
}

export const appendTheme = (themeData): void => {
  const theme: ITheme = {
    body_background_color: themeData?.body_background_color
      ? themeData?.body_background_color
      : '#fff',
    border_background_color: themeData?.border_background_color
      ? themeData?.border_background_color
      : '#262626',
    header_background_color: themeData?.header_background_color
      ? themeData?.header_background_color
      : '#ffffff',
    sidebar_background_color: themeData?.sidebar_background_color
      ? themeData?.sidebar_background_color
      : '#f6f6f6',
    main_color: themeData?.main_color ? themeData?.main_color : '#722ed1',
    main_light_color: themeData?.main_light_color ? themeData?.main_light_color : '#f9f1ff',
    main_color_hover: themeData?.main_color_hover ? themeData?.main_color_hover : '#540cb9',
    primary_text_color: themeData?.primary_text_color ? themeData?.primary_text_color : '#262626',
    secondary_text_color: themeData?.secondary_text_color
      ? themeData?.secondary_text_color
      : '#595959',
    disabled_text_color: themeData?.disabled_text_color
      ? themeData?.disabled_text_color
      : '#8c8c8c',
    main_background_color: 'linear-gradient(0deg, var(--main-color) 0%, rgb(255, 255, 255) 76%)',
    logo_src: themeData?.logo_src ? themeData?.logo_src : null,
    sign_up: themeData?.sign_up ? themeData?.sign_up : null,
    sign_in: themeData?.sign_in ? themeData?.sign_in : null,
    reset_password: themeData?.reset_password ? themeData?.reset_password : null,
  };

  localStorage.setItem('theme_logo', JSON.stringify(theme.logo_src));
  localStorage.setItem('theme_signUp', JSON.stringify(theme.sign_up));
  localStorage.setItem('theme_signIn', JSON.stringify(theme.sign_in));
  localStorage.setItem('theme_forgotPassword', JSON.stringify(theme.reset_password));

  const themeStyles = [
    ['--header-background-color', theme.header_background_color],
    ['--sidebar-background-color', theme.sidebar_background_color],
    ['--main-color', theme.main_color],
    ['--main-light-color', theme.main_light_color],
    ['--main-color-hover', theme.main_color_hover],
    ['--primary-text-color', theme.primary_text_color],
    ['--secondary-text-color', theme.secondary_text_color],
    ['--disabled-text-color', theme.disabled_text_color],
    ['--main-background-color', theme.main_background_color],
    ['--body-background-color', theme.body_background_color],
    ['--border-background-color', theme.border_background_color],
  ];

  themeStyles.forEach(([variable, value]) => {
    document.documentElement.style.setProperty(variable, value);
  });
};
